.root {
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #141724;
        border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #C0C1DB;
        border-radius: 6px;
    }
}
