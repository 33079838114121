@font-face {
    font-family: "ElectronicArtsDisplay";
    src: url("assets/fonts/ElectronicArtsDisplay-Bold.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsDisplay-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "ElectronicArtsDisplay";
    src: url("assets/fonts/ElectronicArtsDisplay-Regular.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsDisplay-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "ElectronicArtsText";
    src: url("assets/fonts/ElectronicArtsText-Bold.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsText-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "ElectronicArtsText";
    src: url("assets/fonts/ElectronicArtsText-BoldItalic.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsText-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "ElectronicArtsText";
    src: url("assets/fonts/ElectronicArtsText-Italic.woff2") format("woff2");
    font-style: italic;
}

@font-face {
    font-family: "ElectronicArtsText";
    src: url("assets/fonts/ElectronicArtsText-Light.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsText-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "ElectronicArtsText";
    src: url("assets/fonts/ElectronicArtsText-LightItalic.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsText-LightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: "ElectronicArtsText";
    src: url("assets/fonts/ElectronicArtsText-Regular.woff2") format("woff2"),
        url("assets/fonts/ElectronicArtsText-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: ElectronicArtsDisplay-Bold, ElectronicArtsDisplay-Regular, ElectronicArtsText-Bold,
        ElectronicArtsText-BoldItalic, ElectronicArtsText-Italic, ElectronicArtsTextLight-Italic,
        ElectronicArtsText-Regular, Arial, Helvetica, sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* https://medium.com/react-camp/how-to-fight-the-body-scroll-2b00267b37ac */
    -webkit-overflow-scrolling: touch;
}

@media (min-width: 600px) {
    body {
        overflow-y: auto;
        /* background-color: #0d1043; */
        background-color: #1d2033;
    }
}

* {
    box-sizing: border-box;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 0;
}

a {
    text-decoration: none;
}

a:visited,
a:focus {
    color: inherit;
}

#VRButton,
#ARButton {
    display: none;
    z-index: 10000 !important;
    left: auto !important;
    right: 15px !important;
    position: fixed !important;
    bottom: 60px !important;
}

/* Cookie banner styles */
#truste-consent-track {
    /* background-color: #1b2064; */
    background-color: #1d2033;
}

#truste-consent-text {
    font-family: ElectronicArtsDisplay, Helvetica, sans-serif;
}

@media (min-width: 600px) {
    #truste-consent-track {
        padding: 16px;
    }
}

.truste-cookie-link {
    color: #ff4747 !important;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.truste-cookie-link:hover {
    color: #fff;
}

#truste-consent-button {
    background-color: #ff4747;
    border: 2px solid #ff4747;
    border-radius: 4px;
    padding: 4px 20px;
    font-family: ElectronicArtsDisplay, Helvetica, sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    white-space: nowrap;
    min-width: 150px;
    font-weight: 700;
    line-height: 1.75;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#truste-consent-button:hover {
    background-color: #fff;
    border: 2px solid #fff;
}

#truste-show-consent {
    border: 2px solid #ff4747;
    background-color: transparent;
    border-radius: 4px;
    padding: 4px 20px;
    font-family: ElectronicArtsDisplay, Helvetica, sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    white-space: nowrap;
    min-width: 150px;
    font-weight: 700;
    line-height: 1.75;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#truste-show-consent:hover {
    background-color: #fff;
    border: 2px solid #fff;
}

#truste-consent-required {
    border: 2px solid #ff4747;
    background-color: transparent;
    border-radius: 4px;
    padding: 4px 20px;
    font-family: ElectronicArtsDisplay, Helvetica, sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    white-space: nowrap;
    min-width: 150px;
    font-weight: 700;
    line-height: 1.75;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#truste-consent-required:hover {
    background-color: #fff;
    border: 2px solid #fff;
}
